.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
    background-color: #E9E9ED !important;
    color: #0F69AF !important;
    font-weight: 900 !important;
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover{
   background-color: #F8F8FC !important;
   color: #0F69AF !important;
   font-weight: 900 !important;
  }
   .MuiOutlinedInput-notchedOutline {
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused {
    border-bottom: #0F69AF 2px solid !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    z-index: 1 !important;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    z-index: 1 !important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0 !important;
    border-radius: 6px !important;
  }
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
    .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
    display: none !important;
  }
  