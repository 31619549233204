@tailwind base;
  @tailwind components;

  label{
    @apply text-[#64709B] text-xs mb-1 
  }
  label span{
    @apply text-[#E61E50]
  }
  input{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] bg-background disabled:opacity-60  text-sm 
  }
  textarea{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] bg-background disabled:opacity-70 text-sm
  }
  select{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] bg-background text-sm
  }
  .primaryButton{
    @apply w-full uppercase md:text-base text-sm bg-[#1890FF] border-[#1890FF] text-white py-2 px-3 rounded-sm hover:bg-blue-600 border hover:border-blue-600 transition duration-300  disabled:bg-[#838F9C]
  }
  .secondaryButton{
    @apply w-full md:text-base text-sm bg-transparent border border-[#1890FF] text-[#1890FF] py-2 px-3 h-fit rounded-sm hover:border-blue-600 disabled:border-[#838F9C] disabled:text-[#838F9C] transition duration-300  disabled:bg-gray-100
  }
  .ghostButton{
    @apply uppercase text-[#1890FF] hover:underline rounded-lg hover:text-blue-700 transition duration-300  disabled:text-[#838F9C]
  }

  .textPrimary {
    @apply text-[#1890FF] font-semibold;
  }
  .textSecondary {
    @apply text-[#64709B] font-semibold mt-2 text-xs;
  }
  .textGreen{
    @apply text-[#15803D] font-semibold mt-2 text-xs
  }
  .textgrey {
    @apply text-[#7D7D89];
  }

  .error{
   @apply text-red-600 text-xs
  }

  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 0 0% 3.9%;

      --card: 0 0% 100%;
      --card-foreground: 0 0% 3.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 0 0% 3.9%;

      --primary: 0 0% 9%;
      --primary-foreground: 0 0% 98%;

      --secondary: 0 0% 96.1%;
      --secondary-foreground: 0 0% 9%;

      --muted: 0 0% 96.1%;
      --muted-foreground: 0 0% 45.1%;

      --accent: 0 0% 96.1%;
      --accent-foreground: 0 0% 9%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --border: 0 0% 89.8%;
      --input: 0 0% 89.8%;
      --ring: 0 0% 3.9%;

      --radius: 0.5rem;
    }

    .dark {
      --background: 0 0% 3.9%;
      --foreground: 0 0% 98%;

      --card: 0 0% 3.9%;
      --card-foreground: 0 0% 98%;

      --popover: 0 0% 3.9%;
      --popover-foreground: 0 0% 98%;

      --primary: 0 0% 98%;
      --primary-foreground: 0 0% 9%;

      --secondary: 0 0% 14.9%;
      --secondary-foreground: 0 0% 98%;

      --muted: 0 0% 14.9%;
      --muted-foreground: 0 0% 63.9%;

      --accent: 0 0% 14.9%;
      --accent-foreground: 0 0% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;

      --border: 0 0% 14.9%;
      --input: 0 0% 14.9%;
      --ring: 0 0% 83.1%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }

  ::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  @font-face {
    font-family: 'Montserrat-regular';
    src: url('./assets/font/Montserrat-Regular.ttf') format('truetype');
   
  }

  body {
    font-family: "Montserrat-regular";
    font-size: 14px;
  }


  [aria-label="A chart."] {
   /* border-radius: 200px; */
   height: 100% !important;
}

.arrow-down {
  position: absolute;
  bottom: -10px; /* Move the arrow slightly below the card */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff; /* Color of the card */
  z-index: 1;
}

/* scroll design */
::-webkit-scrollbar {
  width: 4px !important;
  /* display: none; */
  height: 5px !important;
}


::-webkit-scrollbar-track {
  background: transparent !important; 
}
 

::-webkit-scrollbar-thumb {
  background: #0F69AF; 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #0E5F9E; 
} 


/* From Uiverse.io by asgardOP */ 
.alert {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
}


.point {
  position: absolute;
  top: -2px;
  right: -10px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: red;
  border-radius: 25px;
  animation: loop 1s 0s infinite;
}

@keyframes loop {
  0% {
    background-color: red;
    width: 3px;
    height: 3px;
  }
  100% {
    background-color: rgba(0, 255, 0, 0);
    width: 30px;
    height: 30px;
  }
}
